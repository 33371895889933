import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-geofence",
  templateUrl: "./geofence.component.html",
  styleUrls: ["./geofence.component.css"],
})
export class GeofenceComponent implements OnInit {
  constructor() {}

  polygon = [
    {
      lng: -66.06097877025604,
      lat: 18.353064259603954,
    },
    {
      lng: -66.0611343383789,
      lat: 18.353048984944348,
    },
    {
      lng: -66.06117457151413,
      lat: 18.352982794737095,
    },
    {
      lng: -66.06118530035019,
      lat: 18.35275367459304,
    },
    {
      lng: -66.06118530035019,
      lat: 18.35265438910289,
    },
    {
      lng: -66.06094390153885,
      lat: 18.35242017695127,
    },
    {
      lng: -66.0608097910881,
      lat: 18.352343803354913,
    },
    {
      lng: -66.0606837272644,
      lat: 18.35222924289704,
    },
    {
      lng: -66.06058180332184,
      lat: 18.352129957105415,
    },
    {
      lng: -66.06044501066208,
      lat: 18.352272521301163,
    },
    {
      lng: -66.06042355298996,
      lat: 18.352369261224116,
    },
    {
      lng: -66.0607185959816,
      lat: 18.35277658662111,
    },
    {
      lng: -66.06097877025604,
      lat: 18.353064259603954,
    },
  ];

  luquillo = [
    {
      lng: -65.71686744689941,
      lat: 18.363700186205946,
    },
    {
      lng: -65.71862697601318,
      lat: 18.36221353791687,
    },
    {
      lng: -65.71841239929199,
      lat: 18.35924020292855,
    },
    {
      lng: -65.71547269821167,
      lat: 18.358517226749967,
    },
    {
      lng: -65.71394920349121,
      lat: 18.35926056841086,
    },
    {
      lng: -65.71428179740906,
      lat: 18.362193172782916,
    },
    {
      lng: -65.71686744689941,
      lat: 18.363700186205946,
    },
  ];

  hdMontehiedra = [
    {
      lng: -66.06655776500702,
      lat: 18.337170245890118,
    },
    {
      lng: -66.06655240058899,
      lat: 18.336969110930017,
    },
    {
      lng: -66.06668382883072,
      lat: 18.33717279190077,
    },
    {
      lng: -66.066737473011,
      lat: 18.337139693759358,
    },
    {
      lng: -66.06658458709717,
      lat: 18.33685199426317,
    },
    {
      lng: -66.06656312942505,
      lat: 18.33668904920339,
    },
    {
      lng: -66.06651484966277,
      lat: 18.33668650318561,
    },
    {
      lng: -66.0665014386177,
      lat: 18.336620306710223,
    },
    {
      lng: -66.06652826070786,
      lat: 18.336599938558855,
    },
    {
      lng: -66.0664451122284,
      lat: 18.335950702476577,
    },
    {
      lng: -66.06641292572021,
      lat: 18.335950702476577,
    },
    {
      lng: -66.06638878583908,
      lat: 18.335777572442872,
    },
    {
      lng: -66.06605887413025,
      lat: 18.335810670845074,
    },
    {
      lng: -66.06608033180237,
      lat: 18.33598380084564,
    },
    {
      lng: -66.06600254774094,
      lat: 18.33599143892991,
    },
    {
      lng: -66.0659784078598,
      lat: 18.33581576290638,
    },
    {
      lng: -66.06554388999939,
      lat: 18.335866683511284,
    },
    {
      lng: -66.06558948755263,
      lat: 18.336251133594203,
    },
    {
      lng: -66.06566995382308,
      lat: 18.336240949497068,
    },
    {
      lng: -66.06573432683945,
      lat: 18.336745061585013,
    },
    {
      lng: -66.06564044952393,
      lat: 18.33679852793236,
    },
    {
      lng: -66.06568872928618,
      lat: 18.337233896145204,
    },
    {
      lng: -66.06575042009352,
      lat: 18.337315368437512,
    },
    {
      lng: -66.06581747531891,
      lat: 18.33725935624068,
    },
    {
      lng: -66.06655776500702,
      lat: 18.337170245890118,
    },
  ];

  hdEscorial = [
    {
      lng: -65.99994242191313,
      lat: 18.393802057842798,
    },
    {
      lng: -65.99980294704437,
      lat: 18.392702538578206,
    },
    {
      lng: -65.99785029888153,
      lat: 18.393165762827387,
    },
    {
      lng: -65.99801659584045,
      lat: 18.39428564048295,
    },
    {
      lng: -65.9993040561676,
      lat: 18.393954768296837,
    },
    {
      lng: -65.99959909915923,
      lat: 18.394000581406658,
    },
    {
      lng: -65.99994242191313,
      lat: 18.393802057842798,
    },
  ];

  hdCaguas = [
    {
      lng: -66.03902220726013,
      lat: 18.241299144360752,
    },
    {
      lng: -66.04093730449677,
      lat: 18.23972483223797,
    },
    {
      lng: -66.04031503200531,
      lat: 18.238965692818944,
    },
    {
      lng: -66.03828191757202,
      lat: 18.24037697620613,
    },
    {
      lng: -66.03902220726013,
      lat: 18.241299144360752,
    },
  ];

  hdColobos = [
    {
      lng: -65.92468500137329,
      lat: 18.37481909470122,
    },
    {
      lng: -65.92474400997162,
      lat: 18.375995091195552,
    },
    {
      lng: -65.92661082744598,
      lat: 18.37590854627232,
    },
    {
      lng: -65.92641770839691,
      lat: 18.374625639964492,
    },
    {
      lng: -65.92468500137329,
      lat: 18.37481909470122,
    },
  ];

  hdHatillo = [
    {
      lng: -66.79158568382263,
      lat: 18.489667361024967,
    },
    {
      lng: -66.79171979427338,
      lat: 18.489677536047445,
    },
    {
      lng: -66.7917412519455,
      lat: 18.4892959722906,
    },
    {
      lng: -66.7918860912323,
      lat: 18.489306147335135,
    },
    {
      lng: -66.7918860912323,
      lat: 18.48915352160354,
    },
    {
      lng: -66.79160177707672,
      lat: 18.48913825902292,
    },
    {
      lng: -66.79159641265869,
      lat: 18.4890873837443,
    },
    {
      lng: -66.79072201251984,
      lat: 18.48905177104029,
    },
    {
      lng: -66.79072201251984,
      lat: 18.48896528301401,
    },
    {
      lng: -66.79046988487244,
      lat: 18.48895510794923,
    },
    {
      lng: -66.79046452045439,
      lat: 18.48905177104029,
    },
    {
      lng: -66.79033577442169,
      lat: 18.48904668351054,
    },
    {
      lng: -66.79029822349548,
      lat: 18.48977419873096,
    },
    {
      lng: -66.79088830947875,
      lat: 18.489799636270195,
    },
    {
      lng: -66.79089367389679,
      lat: 18.489840336325095,
    },
    {
      lng: -66.79108142852783,
      lat: 18.489840336325095,
    },
    {
      lng: -66.79108679294586,
      lat: 18.489794548762646,
    },
    {
      lng: -66.79157495498657,
      lat: 18.48980981128482,
    },
    {
      lng: -66.79158568382263,
      lat: 18.489667361024967,
    },
  ];

  hdMayaguez = [
    {
      lng: -67.16180562973021,
      lat: 18.242027704692408,
    },
    {
      lng: -67.16443419456482,
      lat: 18.24117177335983,
    },
    {
      lng: -67.16442346572876,
      lat: 18.23914401445684,
    },
    {
      lng: -67.1617841720581,
      lat: 18.239123634850333,
    },
    {
      lng: -67.16180562973021,
      lat: 18.242027704692408,
    },
  ];

  hdPlazaDelSol = [
    {
      lng: -66.16291880607605,
      lat: 18.406909212275096,
    },
    {
      lng: -66.16266131401062,
      lat: 18.404603443434368,
    },
    {
      lng: -66.16027414798737,
      lat: 18.405137895914745,
    },
    {
      lng: -66.16025805473328,
      lat: 18.406547824497363,
    },
    {
      lng: -66.16055846214294,
      lat: 18.4074487475457,
    },
    {
      lng: -66.16149723529816,
      lat: 18.407718514546954,
    },
    {
      lng: -66.16291880607605,
      lat: 18.406909212275096,
    },
  ];

  hdPonce = [
    {
      lng: -66.6422563791275,
      lat: 17.999618860222963,
    },
    {
      lng: -66.64245754480362,
      lat: 17.9984709344429,
    },
    {
      lng: -66.64222687482834,
      lat: 17.9984275682114,
    },
    {
      lng: -66.64224028587341,
      lat: 17.99832808093414,
    },
    {
      lng: -66.64186745882033,
      lat: 17.9982694089238,
    },
    {
      lng: -66.64184331893921,
      lat: 17.99836889623416,
    },
    {
      lng: -66.64180845022202,
      lat: 17.998376549101867,
    },
    {
      lng: -66.64177894592285,
      lat: 17.998544912107317,
    },
    {
      lng: -66.64165019989014,
      lat: 17.998527055432543,
    },
    {
      lng: -66.64163142442703,
      lat: 17.998652052118008,
    },
    {
      lng: -66.64176285266876,
      lat: 17.99867756163476,
    },
    {
      lng: -66.64158046245575,
      lat: 17.99976171268611,
    },
    {
      lng: -66.64167165756226,
      lat: 17.999771916429058,
    },
    {
      lng: -66.64171189069748,
      lat: 17.99963161491187,
    },
    {
      lng: -66.64209544658661,
      lat: 17.999692837405828,
    },
    {
      lng: -66.64212226867676,
      lat: 17.999603554595044,
    },
    {
      lng: -66.6422563791275,
      lat: 17.999618860222963,
    },
  ];

  hdRexville = [
    {
      lng: -66.18800282478333,
      lat: 18.360187195314815,
    },
    {
      lng: -66.18865728378296,
      lat: 18.359270751151108,
    },
    {
      lng: -66.1878365278244,
      lat: 18.357040716698993,
    },
    {
      lng: -66.18557810783386,
      lat: 18.35847140421411,
    },
    {
      lng: -66.18654370307922,
      lat: 18.359723882484516,
    },
    {
      lng: -66.18800282478333,
      lat: 18.360187195314815,
    },
  ];

  lancoSalinas = [
    {
      lng: -66.29151463508606,
      lat: 17.97321212624318,
    },
    {
      lng: -66.2918096780777,
      lat: 17.972038515328183,
    },
    {
      lng: -66.29075288772583,
      lat: 17.97177827880812,
    },
    {
      lng: -66.2904417514801,
      lat: 17.972972302037988,
    },
    {
      lng: -66.29151463508606,
      lat: 17.97321212624318,
    },
  ];

  lancoArecibo = [
    {
      lng: -66.75657212734222,
      lat: 18.477039692544782,
    },
    {
      lng: -66.75660967826843,
      lat: 18.476627573266107,
    },
    {
      lng: -66.75601422786713,
      lat: 18.476602133772,
    },
    {
      lng: -66.75601422786713,
      lat: 18.47701934099872,
    },
    {
      lng: -66.75657212734222,
      lat: 18.477039692544782,
    },
  ];

  lancoBayamon = [
    {
      lng: -66.14947557449341,
      lat: 18.382058218596303,
    },
    {
      lng: -66.15206122398376,
      lat: 18.382170213906534,
    },
    {
      lng: -66.15228652954102,
      lat: 18.381620418049266,
    },
    {
      lng: -66.15225434303284,
      lat: 18.38051063958921,
    },
    {
      lng: -66.15202903747559,
      lat: 18.37987938626911,
    },
    {
      lng: -66.15161061286926,
      lat: 18.379370309327737,
    },
    {
      lng: -66.14883184432983,
      lat: 18.381111346247934,
    },
    {
      lng: -66.14947557449341,
      lat: 18.382058218596303,
    },
  ];

  lancoCaboRojo = [
    {
      lng: -67.15773403644562,
      lat: 18.095913376934995,
    },
    {
      lng: -67.15783059597015,
      lat: 18.094302059434447,
    },
    {
      lng: -67.15577602386475,
      lat: 18.094317356822106,
    },
    {
      lng: -67.15599596500397,
      lat: 18.096010259477353,
    },
    {
      lng: -67.15773403644562,
      lat: 18.095913376934995,
    },
  ];

  lancoCaguas = [
    {
      lng: -66.04198336601257,
      lat: 18.223359293865897,
    },
    {
      lng: -66.04220867156982,
      lat: 18.222686704559916,
    },
    {
      lng: -66.04088366031647,
      lat: 18.22235040893216,
    },
    {
      lng: -66.04078710079193,
      lat: 18.223063762521306,
    },
    {
      lng: -66.04198336601257,
      lat: 18.223359293865897,
    },
  ];

  lancoCarolina = [
    {
      lng: -65.99347293376923,
      lat: 18.415114037670776,
    },
    {
      lng: -65.99332273006439,
      lat: 18.414330218891813,
    },
    {
      lng: -65.99244832992552,
      lat: 18.414477821402258,
    },
    {
      lng: -65.99264681339264,
      lat: 18.415276908657358,
    },
    {
      lng: -65.99347293376923,
      lat: 18.415114037670776,
    },
  ];

  lancoDeDiego = [
    {
      lng: -66.08732879161835,
      lat: 18.403992638568408,
    },
    {
      lng: -66.08796179294586,
      lat: 18.40379412652047,
    },
    {
      lng: -66.08779013156891,
      lat: 18.403290210294085,
    },
    {
      lng: -66.08716249465941,
      lat: 18.403483632858393,
    },
    {
      lng: -66.08732879161835,
      lat: 18.403992638568408,
    },
  ];

  lancoGuaynabo = [
    {
      lng: -66.10852897167206,
      lat: 18.377000536385506,
    },
    {
      lng: -66.1086255311966,
      lat: 18.37598745488055,
    },
    {
      lng: -66.10742926597595,
      lat: 18.375995091195552,
    },
    {
      lng: -66.10738098621368,
      lat: 18.37700308180894,
    },
    {
      lng: -66.10852897167206,
      lat: 18.377000536385506,
    },
  ];

  lancoSanLorenzo = [
    {
      lng: -65.96860885620117,
      lat: 18.189155854101095,
    },
    {
      lng: -65.97078680992126,
      lat: 18.187178453571004,
    },
    {
      lng: -65.96956372261047,
      lat: 18.18579222127436,
    },
    {
      lng: -65.96718192100525,
      lat: 18.18804484316104,
    },
    {
      lng: -65.96860885620117,
      lat: 18.189155854101095,
    },
  ];

  sanLorenzo = [
    {
      lng: -66.04499816894531,
      lat: 18.31998127358338,
    },
    {
      lng: -66.16996765136719,
      lat: 18.188911228446756,
    },
    {
      lng: -66.13906860351562,
      lat: 18.038156766015522,
    },
    {
      lng: -65.89462280273438,
      lat: 17.977426855928485,
    },
    {
      lng: -65.71815490722656,
      lat: 18.134758980503445,
    },
    {
      lng: -65.906982421875,
      lat: 18.2939055695918,
    },
    {
      lng: -66.04499816894531,
      lat: 18.31998127358338,
    },
  ];

  wBarceloneta = [
    {
      lng: -66.55159771442413,
      lat: 18.432616696874238,
    },
    {
      lng: -66.55156016349792,
      lat: 18.43255053722467,
    },
    {
      lng: -66.55211269855499,
      lat: 18.432357147333846,
    },
    {
      lng: -66.55190348625183,
      lat: 18.43160394252747,
    },
    {
      lng: -66.551753282547,
      lat: 18.431644656385163,
    },
    {
      lng: -66.55167281627655,
      lat: 18.431542871722865,
    },
    {
      lng: -66.55050873756409,
      lat: 18.431822779399162,
    },
    {
      lng: -66.55075550079346,
      lat: 18.432708302500806,
    },
    {
      lng: -66.5510880947113,
      lat: 18.432657410492066,
    },
    {
      lng: -66.5511631965637,
      lat: 18.43285080004514,
    },
    {
      lng: -66.55163526535034,
      lat: 18.432743926897963,
    },
    {
      lng: -66.55159771442413,
      lat: 18.432616696874238,
    },
  ];

  wBayamon = [
    {
      lng: -66.16428941488266,
      lat: 18.407423297806787,
    },
    {
      lng: -66.1650538444519,
      lat: 18.40734949354265,
    },
    {
      lng: -66.16508334875107,
      lat: 18.40736985334281,
    },
    {
      lng: -66.16533547639847,
      lat: 18.40733931364166,
    },
    {
      lng: -66.16534888744354,
      lat: 18.40741311791017,
    },
    {
      lng: -66.16560101509094,
      lat: 18.40739021314057,
    },
    {
      lng: -66.1655580997467,
      lat: 18.406906667293406,
    },
    {
      lng: -66.1656653881073,
      lat: 18.406860857616405,
    },
    {
      lng: -66.16563320159912,
      lat: 18.406639444005744,
    },
    {
      lng: -66.16552591323853,
      lat: 18.406644533977,
    },
    {
      lng: -66.16549104452133,
      lat: 18.40632895547441,
    },
    {
      lng: -66.16501361131668,
      lat: 18.40636458533431,
    },
    {
      lng: -66.16495996713638,
      lat: 18.406407850154295,
    },
    {
      lng: -66.16464614868164,
      lat: 18.406423120088157,
    },
    {
      lng: -66.16461396217346,
      lat: 18.406448569974916,
    },
    {
      lng: -66.16417676210403,
      lat: 18.406499469737163,
    },
    {
      lng: -66.16428941488266,
      lat: 18.407423297806787,
    },
  ];

  wCaguas = [
    {
      lng: -66.0159981250763,
      lat: 18.2476166288875,
    },
    {
      lng: -66.01607859134674,
      lat: 18.24763700749829,
    },
    {
      lng: -66.01616978645325,
      lat: 18.24734661206911,
    },
    {
      lng: -66.01630926132202,
      lat: 18.247351706729955,
    },
    {
      lng: -66.0166472196579,
      lat: 18.246434665373712,
    },
    {
      lng: -66.01657748222351,
      lat: 18.246383718489817,
    },
    {
      lng: -66.01664185523987,
      lat: 18.246220688360985,
    },
    {
      lng: -66.01566553115845,
      lat: 18.245879343533417,
    },
    {
      lng: -66.0152417421341,
      lat: 18.246898281330846,
    },
    {
      lng: -66.01510763168335,
      lat: 18.246913565352326,
    },
    {
      lng: -66.01503252983093,
      lat: 18.247158109513258,
    },
    {
      lng: -66.01533830165863,
      lat: 18.247270192138686,
    },
    {
      lng: -66.01531684398651,
      lat: 18.247377180031883,
    },
    {
      lng: -66.01563334465025,
      lat: 18.247463789230515,
    },
    {
      lng: -66.01559042930603,
      lat: 18.247626818193186,
    },
    {
      lng: -66.01571917533875,
      lat: 18.247708332617186,
    },
    {
      lng: -66.01567089557648,
      lat: 18.247901929220898,
    },
    {
      lng: -66.01586401462555,
      lat: 18.247968159588456,
    },
    {
      lng: -66.0159981250763,
      lat: 18.2476166288875,
    },
  ];

  wCanovanas = [
    {
      lng: -65.89158654212952,
      lat: 18.381620418049266,
    },
    {
      lng: -65.8901059627533,
      lat: 18.378291061241782,
    },
    {
      lng: -65.88620066642761,
      lat: 18.378891775632525,
    },
    {
      lng: -65.88720917701721,
      lat: 18.383005085744752,
    },
    {
      lng: -65.89158654212952,
      lat: 18.381620418049266,
    },
  ];

  wCarolina = [
    {
      lng: -65.99848866462708,
      lat: 18.39404130416072,
    },
    {
      lng: -65.99835991859436,
      lat: 18.393486455808848,
    },
    {
      lng: -65.99785029888153,
      lat: 18.39360353405018,
    },
    {
      lng: -65.9977376461029,
      lat: 18.393145401348097,
    },
    {
      lng: -65.99643409252167,
      lat: 18.393445732923645,
    },
    {
      lng: -65.99636971950531,
      lat: 18.39325229908754,
    },
    {
      lng: -65.99606394767761,
      lat: 18.39334392566856,
    },
    {
      lng: -65.99641263484955,
      lat: 18.39458087974335,
    },
    {
      lng: -65.99848866462708,
      lat: 18.39404130416072,
    },
  ];

  wCayey = [
    {
      lng: -66.13454639911652,
      lat: 18.122997610583816,
    },
    {
      lng: -66.13488972187042,
      lat: 18.122849759984597,
    },
    {
      lng: -66.13486289978027,
      lat: 18.121692442359386,
    },
    {
      lng: -66.13365590572357,
      lat: 18.12168734402693,
    },
    {
      lng: -66.13361835479736,
      lat: 18.12152929564717,
    },
    {
      lng: -66.13306045532227,
      lat: 18.12161596735697,
    },
    {
      lng: -66.13288879394531,
      lat: 18.122757990584358,
    },
    {
      lng: -66.13349497318268,
      lat: 18.122997610583816,
    },
    {
      lng: -66.13454639911652,
      lat: 18.122997610583816,
    },
  ];

  wFajardo = [
    { lng: -65.6747031211853, lat: 18.34360898695414 },
    { lng: -65.67496061325073, lat: 18.34307434380343 },
    { lng: -65.6752073764801, lat: 18.34309980303859 },
    { lng: -65.67528784275055, lat: 18.342926680165522 },
    { lng: -65.67412912845612, lat: 18.342381851168547 },
    { lng: -65.6738555431366, lat: 18.34253969899861 },
    { lng: -65.67379117012024, lat: 18.342692454825908 },
    { lng: -65.67351758480072, lat: 18.342636444371585 },
    { lng: -65.6733191013336, lat: 18.343008149774448 },
    { lng: -65.67362487316132, lat: 18.343165997032443 },
    { lng: -65.67359805107117, lat: 18.343298384944116 },
    { lng: -65.67467629909515, lat: 18.343726099042502 },
    { lng: -65.6747031211853, lat: 18.34360898695414 },
  ];

  wGuayama = [
    {
      lng: -66.09579652547836,
      lat: 17.97993472542971,
    },
    {
      lng: -66.09447687864304,
      lat: 17.97993472542971,
    },
    {
      lng: -66.09447687864304,
      lat: 17.98112614230401,
    },
    {
      lng: -66.09579652547836,
      lat: 17.98112614230401,
    },
    {
      lng: -66.09579652547836,
      lat: 17.97993472542971,
    },
  ];

  wHatillo = [
    {
      lng: -66.79623663425446,
      lat: 18.48472222859475,
    },
    {
      lng: -66.79633319377899,
      lat: 18.483750483122225,
    },
    {
      lng: -66.79603278636932,
      lat: 18.48371995699718,
    },
    {
      lng: -66.79607033729553,
      lat: 18.48354697551927,
    },
    {
      lng: -66.79535150527954,
      lat: 18.48351136166391,
    },
    {
      lng: -66.79532468318939,
      lat: 18.48363346628005,
    },
    {
      lng: -66.7948043346405,
      lat: 18.483572413982866,
    },
    {
      lng: -66.7946594953537,
      lat: 18.484635738383393,
    },
    {
      lng: -66.79544270038605,
      lat: 18.48477310516928,
    },
    {
      lng: -66.79623663425446,
      lat: 18.48472222859475,
    },
  ];

  wIsabela = [
    {
      lng: -67.0224380493164,
      lat: 18.468634298536507,
    },
    {
      lng: -67.02329635620117,
      lat: 18.467820194913593,
    },
    {
      lng: -67.02285647392273,
      lat: 18.46738261261997,
    },
    {
      lng: -67.02284038066864,
      lat: 18.467168909233735,
    },
    {
      lng: -67.02266871929169,
      lat: 18.46709258653129,
    },
    {
      lng: -67.02175676822662,
      lat: 18.467993192256706,
    },
    {
      lng: -67.0224380493164,
      lat: 18.468634298536507,
    },
  ];

  wManati = [
    {
      lng: -66.45862698554993,
      lat: 18.44132919130633,
    },
    {
      lng: -66.45854651927948,
      lat: 18.440927164192775,
    },
    {
      lng: -66.45869135856628,
      lat: 18.440901719407105,
    },
    {
      lng: -66.45844995975494,
      lat: 18.44007221733055,
    },
    {
      lng: -66.45688891410828,
      lat: 18.440474246444417,
    },
    {
      lng: -66.45717322826384,
      lat: 18.441619260918,
    },
    {
      lng: -66.45862698554993,
      lat: 18.44132919130633,
    },
  ];

  wMayaguez = [
    {
      lng: -67.1400797367096,
      lat: 18.155888713784513,
    },
    {
      lng: -67.14061617851257,
      lat: 18.155368784776382,
    },
    {
      lng: -67.1394681930542,
      lat: 18.15419129277377,
    },
    {
      lng: -67.13873326778412,
      lat: 18.154889633144073,
    },
    {
      lng: -67.13976323604584,
      lat: 18.155893811120077,
    },
    {
      lng: -67.1400797367096,
      lat: 18.155888713784513,
    },
  ];

  wPonceBaramaya = [
    {
      lng: -66.64151072502136,
      lat: 17.998351039541564,
    },
    {
      lng: -66.64305031299591,
      lat: 17.99841226248009,
    },
    {
      lng: -66.64361894130707,
      lat: 17.997279634676943,
    },
    {
      lng: -66.64372622966766,
      lat: 17.99657556507912,
    },
    {
      lng: -66.6416609287262,
      lat: 17.99649903560565,
    },
    {
      lng: -66.64151072502136,
      lat: 17.998351039541564,
    },
  ];

  wPonceNorte = [
    {
      lng: -66.57885968685149,
      lat: 18.044940645518587,
    },
    {
      lng: -66.57873094081879,
      lat: 18.044553002309474,
    },
    {
      lng: -66.57880067825317,
      lat: 18.04445099079666,
    },
    {
      lng: -66.57852709293365,
      lat: 18.04378281392462,
    },
    {
      lng: -66.57690167427063,
      lat: 18.044241867010374,
    },
    {
      lng: -66.57737374305725,
      lat: 18.04542009777949,
    },
    {
      lng: -66.57885968685149,
      lat: 18.044940645518587,
    },
  ];

  wSantaIsabel = [
    {
      lng: -66.37997925281525,
      lat: 18.017216902192537,
    },
    {
      lng: -66.38078391551971,
      lat: 18.01772193673912,
    },
    {
      lng: -66.38117551803589,
      lat: 18.017584200188196,
    },
    {
      lng: -66.38149738311768,
      lat: 18.017130178942903,
    },
    {
      lng: -66.38157248497009,
      lat: 18.016553723316232,
    },
    {
      lng: -66.38207674026489,
      lat: 18.015747702551682,
    },
    {
      lng: -66.38174414634705,
      lat: 18.015482429088426,
    },
    {
      lng: -66.38123989105225,
      lat: 18.016104800813736,
    },
    {
      lng: -66.38092339038849,
      lat: 18.015900744752624,
    },
    {
      lng: -66.37997925281525,
      lat: 18.017216902192537,
    },
  ];

  wSanturce = [
    {
      lng: -66.07591331005096,
      lat: 18.448331436720476,
    },
    {
      lng: -66.07641220092773,
      lat: 18.44757830193026,
    },
    {
      lng: -66.07555389404297,
      lat: 18.447049070102054,
    },
    {
      lng: -66.07501745223999,
      lat: 18.447751319674254,
    },
    {
      lng: -66.07591331005096,
      lat: 18.448331436720476,
    },
  ];

  wToaBaja = [
    {
      lng: -66.25472009181976,
      lat: 18.413938308163573,
    },
    {
      lng: -66.25484883785248,
      lat: 18.413576935142775,
    },
    {
      lng: -66.25499904155731,
      lat: 18.41359220444069,
    },
    {
      lng: -66.25509560108185,
      lat: 18.413205381809078,
    },
    {
      lng: -66.25494003295898,
      lat: 18.413159573808294,
    },
    {
      lng: -66.255122423172,
      lat: 18.41254370950267,
    },
    {
      lng: -66.25416219234467,
      lat: 18.41231466849625,
    },
    {
      lng: -66.25410854816437,
      lat: 18.41253352990886,
    },
    {
      lng: -66.25398516654968,
      lat: 18.41251317071943,
    },
    {
      lng: -66.25373840332031,
      lat: 18.413520947705464,
    },
    {
      lng: -66.25387251377106,
      lat: 18.413587114674872,
    },
    {
      lng: -66.25387787818909,
      lat: 18.413775435910097,
    },
    {
      lng: -66.25472009181976,
      lat: 18.413938308163573,
    },
  ];

  wHumacao = [
    {
      lng: -65.80856680870056,
      lat: 18.14148304959499,
    },
    {
      lng: -65.80813765525816,
      lat: 18.14035644194549,
    },
    {
      lng: -65.80652296543121,
      lat: 18.14108032642712,
    },
    {
      lng: -65.80682337284088,
      lat: 18.14220183167826,
    },
    {
      lng: -65.80856680870056,
      lat: 18.14148304959499,
    },
  ];

  wRunner = [
    {
      lng: -65.80856680870056,
      lat: 18.14148304959499,
    },
    {
      lng: -65.80813765525816,
      lat: 18.14035644194549,
    },
    {
      lng: -65.80652296543121,
      lat: 18.14108032642712,
    },
    {
      lng: -65.80682337284088,
      lat: 18.14220183167826,
    },
    {
      lng: -65.80856680870056,
      lat: 18.14148304959499,
    },
  ];

  lRunner = [
    {
      lng: -65.80856680870056,
      lat: 18.14148304959499,
    },
    {
      lng: -65.80813765525816,
      lat: 18.14035644194549,
    },
    {
      lng: -65.80652296543121,
      lat: 18.14108032642712,
    },
    {
      lng: -65.80682337284088,
      lat: 18.14220183167826,
    },
    {
      lng: -65.80856680870056,
      lat: 18.14148304959499,
    },
  ];

  lancoManati = [
    {
      lng: -66.48179054260254,
      lat: 18.432774462089647,
    },
    {
      lng: -66.48192465305328,
      lat: 18.431176446439682,
    },
    {
      lng: -66.47960186004639,
      lat: 18.431715905612908,
    },
    {
      lng: -66.4793872833252,
      lat: 18.432642142886507,
    },
    {
      lng: -66.48179054260254,
      lat: 18.432774462089647,
    },
  ];

  lancoLasPiedras = [
    {
      lng: -65.87808430194855,
      lat: 18.17439106206407,
    },
    {
      lng: -65.87745130062102,
      lat: 18.173810026401725,
    },
    {
      lng: -65.87658762931824,
      lat: 18.174661192020324,
    },
    {
      lng: -65.87714284658432,
      lat: 18.175155579725327,
    },
    {
      lng: -65.87808430194855,
      lat: 18.17439106206407,
    },
  ];

  lancoLasPiedrasGerente = [
    {
      lng: -65.80823421478271,
      lat: 18.141416778757698,
    },
    {
      lng: -65.8082503080368,
      lat: 18.14083563341647,
    },
    {
      lng: -65.80808937549591,
      lat: 18.14048388661382,
    },
    {
      lng: -65.80665171146393,
      lat: 18.14110071749589,
    },
    {
      lng: -65.80707013607024,
      lat: 18.141916358296758,
    },
    {
      lng: -65.80823421478271,
      lat: 18.141416778757698,
    },
  ];

  lancoDeDiegoGerente = [
    {
      lng: -66.08751520514488,
      lat: 18.403621064548357,
    },
    {
      lng: -66.08737707138062,
      lat: 18.403661785028028,
    },
    {
      lng: -66.0874092578888,
      lat: 18.403747043501152,
    },
    {
      lng: -66.08754873275757,
      lat: 18.403706323041646,
    },
    {
      lng: -66.08751520514488,
      lat: 18.403621064548357,
    },
  ];

  lancoGuaynaboGerente = [
    {
      lng: -66.10803008079527,
      lat: 18.37663654044931,
    },
    {
      lng: -66.10799252986908,
      lat: 18.376611086159304,
    },
    {
      lng: -66.10799252986908,
      lat: 18.376570359287467,
    },
    {
      lng: -66.10804080963135,
      lat: 18.376539814127273,
    },
    {
      lng: -66.10804617404938,
      lat: 18.376432906024032,
    },
    {
      lng: -66.10790133476257,
      lat: 18.376427815160323,
    },
    {
      lng: -66.10787987709045,
      lat: 18.376463451203154,
    },
    {
      lng: -66.10789597034454,
      lat: 18.37649908723862,
    },
    {
      lng: -66.10789060592651,
      lat: 18.376570359287467,
    },
    {
      lng: -66.1078530550003,
      lat: 18.376611086159304,
    },
    {
      lng: -66.10803008079527,
      lat: 18.37663654044931,
    },
  ];

  lancoPonce = [
    {
      lng: -66.62554621696472,
      lat: 17.9966061768592,
    },
    {
      lng: -66.62601560354231,
      lat: 17.996613829803387,
    },
    {
      lng: -66.62606120109558,
      lat: 17.99613934663555,
    },
    {
      lng: -66.62553548812866,
      lat: 17.99612148971721,
    },
    {
      lng: -66.62554621696472,
      lat: 17.9966061768592,
    },
  ];

  ngOnInit() {}

  fetchArea(place) {
    switch (place) {
      case "Luquillo":
        return this.luquillo;
        break;
      case "Home Depot Montehiedra":
        return this.hdMontehiedra;
        break;
      case "Home Depot Escorial":
        return this.hdEscorial;
        break;
      case "Home Depot Colobos":
        return this.hdColobos;
        break;
      case "Home Depot Plaza Del Sol":
        return this.hdPlazaDelSol;
        break;
      case "Home Depot Mayaguez":
        return this.hdMayaguez;
        break;
      case "Home Depot Ponce":
        return this.hdPonce;
        break;
      case "Home Depot Hatillo":
        return this.hdHatillo;
        break;
      case "Home Depot Caguas":
        return this.hdCaguas;
        break;
      case "Home Depot Rexville":
        return this.hdRexville;
        break;
      case "Home Depot Montehiedra":
        return this.hdMontehiedra;
        break;
      case "Home Depot Montehiedra":
        return this.hdMontehiedra;
        break;
      case "Home Depot Montehiedra":
        return this.hdMontehiedra;
        break;
      case "Home Depot Montehiedra":
        return this.hdMontehiedra;
        break;
      case "Home Depot Montehiedra":
        return this.hdMontehiedra;
        break;
      case "104 San Lorenzo":
        return this.lancoSanLorenzo;
        break;
      case "San Lorenzo":
        return this.sanLorenzo;
        break;
      case "Walmart Barceloneta":
        return this.wBarceloneta;
        break;
      case "Walmart Bayamon":
        return this.wBayamon;
        break;
      case "Walmart Caguas":
        return this.wCaguas;
        break;
      case "Walmart Canovanas":
        return this.wCaguas;
        break;
      case "Walmart Carolina":
        return this.wCarolina;
        break;
      case "Walmart Cayey":
        return this.wCayey;
        break;
      case "Walmart Fajardo":
        return this.wFajardo;
        break;
      case "Walmart Guayama":
        return this.wGuayama;
        break;
      case "Walmart Hatillo":
        return this.wHatillo;
        break;
      case "Walmart Isabela":
        return this.wIsabela;
        break;
      case "Walmart Manati":
        return this.wManati;
        break;
      case "Walmart Mayaguez":
        return this.wMayaguez;
        break;
      case "Walmart Ponce Baramaya":
        return this.wPonceBaramaya;
        break;
      case "Walmart Ponce Norte":
        return this.wPonceNorte;
        break;
      case "Walmart Santa Isabel":
        return this.wSantaIsabel;
        break;
      case "Walmart Santurce":
        return this.wSanturce;
        break;
      case "Walmart Toa Baja":
        return this.wToaBaja;
        break;
      case "Walmart Humacao":
        return this.wHumacao;
        break;
      case "Walmart Runner":
        return this.wRunner;
        break;

      case "Walmart Runner":
        return this.wRunner;
        break;

      case "Lanco Runner":
        return this.lRunner;
        break;

      case "314 Caguas":
        return this.lancoCaguas;
        break;
      case "307 Salinas":
        return this.lancoSalinas;
        break;
      case "311 Manati":
        return this.lancoManati;
        break;
      case "308 Carolina":
        return this.lancoCarolina;
        break;
      case "301 De Diego":
        return this.lancoDeDiego;
        break;
      case "103 Guaynabo (Gerente)":
        return this.lancoGuaynaboGerente;
        break;
      case "320 Arecibo":
        return this.lancoArecibo;
        break;
      case "309 Las Piedras":
        return this.lancoLasPiedras;
        break;
      case "301 De Diego (Gerente)":
        return this.lancoDeDiegoGerente;
        break;
      case "305 Cabo Rojo":
        return this.lancoCaboRojo;
        break;
      case "309 Las Piedras (Gerente)":
        return this.lancoLasPiedrasGerente;
        break;
      case "103 Guaynabo":
        return this.lancoGuaynabo;
        break;
      case "101 Bayamon":
        return this.lancoBayamon;
        break;
      case "321 Ponce":
        return this.lancoPonce;
        break;

      default:
      // code block
    }
  }
}
