import { Injectable } from "@angular/core";

import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import "rxjs/add/operator/map";
import { Http, Response, Headers, RequestOptions } from "@angular/http";

const url = environment.urlLink;

@Injectable()
export class HttpService {
  private headers: Headers;
  private options: RequestOptions;
  constructor(private http: Http) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.options = new RequestOptions({ headers: this.headers });
  }

  getInspection(id: String) {
    return this.http.get(url + "/ping").map((response) => response.json());
  }

  login(data) {
    // const headers = new Headers({'Content-Type': 'application/json'});
    // const options = new RequestOptions({headers: headers});
    console.log(data);
    const body = data;
    return this.http
      .post(url + "/login", body, this.options)
      .map((res: Response) => res.json());
  }

  getPunchs(id: String) {
    return this.http
      .get(url + "/gPunch/" + id)
      .map((response) => response.json());
  }

  getIp() {
    return this.http
      .get(
        "http://api.ipstack.com/check?access_key=55044c208bc04d20afaf56581c74922e"
      )
      .map((response) => response.json());
  }

  cPunch(data) {
    console.log(data);
    const body = data;
    return this.http
      .post(url + "/cPunch", body, this.options)
      .map((res: Response) => res.json());
  }

  uPunch(data) {
    console.log(data);
    const body = data;
    return this.http
      .post(url + "/uPunch", body, this.options)
      .map((res: Response) => res.json());
  }

  failedPunch(data) {
    console.log(data);
    const body = data;
    return this.http
      .post(url + "/failedPunch", body, this.options)
      .map((res: Response) => res.json());
  }

  // Save
  ping(data) {
    // const headers = new Headers({'Content-Type': 'application/json'});
    // const options = new RequestOptions({headers: headers});
    console.log(data);
    const body = data;
    return this.http
      .post(url + "/ping", body, this.options)
      .map((res: Response) => res.json());
  }
}
