import { Injectable } from "@angular/core";
declare const Pusher: any;

@Injectable()
export class PusherService {
  constructor() {
    const pusher = new Pusher("e0719cbeef0d3eacde04", {
      cluster: "mt1",
    });
    this.channel = pusher.subscribe("location");
  }
  channel;

  public init() {
    return this.channel;
  }
}
