import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  { path: "", loadChildren: "../app/login/login.module#LoginModule" },
  {
    path: "home",
    loadChildren: "../app/home/home.module#HomeModule",
  },
  {
    path: "employee_list",
    loadChildren:
      "../app/employee-list/employee-list.module#EmployeeListModule",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
